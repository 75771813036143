import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          <path d="M5901 9243 c-20 -18 -21 -27 -27 -500 -5 -331 -4 -486 3 -495 6 -7
14 -44 18 -83 4 -38 12 -94 19 -124 13 -58 36 -81 36 -37 0 30 7 32 40 9 14
-10 43 -17 73 -17 45 0 53 4 86 40 30 31 40 52 50 104 6 36 17 80 22 99 6 18
15 83 20 145 13 156 31 784 24 824 -8 46 -32 52 -200 52 -121 0 -146 -3 -164
-17z m319 -135 c0 -91 -17 -427 -36 -725 l-7 -103 -128 0 -129 0 0 358 c0 198
3 407 7 465 l6 107 144 0 143 0 0 -102z"/>
<path d="M7410 9120 c-133 -21 -193 -38 -295 -85 -185 -84 -332 -220 -414
-384 -31 -61 -113 -289 -144 -401 -24 -83 -22 -287 2 -370 54 -184 94 -239
261 -361 33 -24 123 -69 137 -68 3 1 9 -3 12 -8 6 -9 87 -49 153 -76 20 -9
126 -45 235 -82 340 -114 434 -175 500 -325 14 -33 19 -63 17 -115 -2 -89 -34
-156 -101 -215 -47 -41 -133 -90 -160 -90 -8 0 -11 -4 -8 -10 4 -6 -13 -10
-42 -11 -37 0 -43 -2 -28 -9 32 -15 -50 -1 -130 22 -85 24 -189 77 -244 124
-56 49 -107 156 -127 269 -9 50 -19 97 -24 105 -6 13 -44 15 -230 18 l-223 2
-36 -26 c-20 -15 -42 -34 -48 -42 -18 -22 -16 -124 2 -148 8 -10 15 -29 15
-42 0 -39 40 -144 77 -206 120 -197 439 -397 769 -481 132 -34 242 -47 384
-47 393 2 704 121 885 339 66 80 138 224 155 311 20 101 8 330 -23 422 -57
176 -129 282 -265 391 -125 101 -369 219 -587 285 -356 108 -544 182 -632 250
-91 69 -136 145 -131 221 l3 48 55 20 c45 16 79 19 195 18 167 -1 195 -3 195
-14 0 -5 18 -14 41 -20 69 -21 165 -112 220 -209 64 -113 106 -155 172 -175
57 -17 129 -12 152 11 7 8 19 14 25 14 32 0 -63 72 -198 150 -51 29 -95 57
-98 62 -3 5 6 18 20 29 14 11 32 26 39 32 6 7 53 46 102 87 50 41 101 85 115
97 14 13 30 23 36 23 17 0 44 -85 51 -160 9 -104 3 -100 158 -100 73 0 136 -4
139 -8 3 -5 -63 -50 -147 -100 -200 -121 -185 -110 -167 -122 27 -17 49 -11
128 38 61 39 266 156 352 202 12 6 15 25 13 89 -5 184 -84 355 -243 524 -97
103 -169 158 -282 212 -214 103 -509 139 -788 95z m570 -63 c197 -54 308 -116
435 -242 100 -99 167 -198 215 -318 32 -80 58 -221 47 -250 -6 -15 -27 -17
-187 -17 -136 0 -181 3 -184 13 -2 6 -10 50 -19 97 -23 117 -66 201 -146 281
-74 73 -137 108 -256 140 -113 31 -354 31 -467 1 -97 -26 -168 -62 -225 -115
-112 -103 -161 -281 -117 -427 52 -175 224 -283 655 -415 606 -184 858 -362
958 -676 42 -130 44 -343 6 -469 -58 -187 -189 -332 -392 -433 -338 -169 -846
-162 -1201 17 -123 62 -183 106 -283 206 -80 79 -104 111 -141 185 -54 106
-80 204 -82 300 l-1 70 190 0 190 0 7 -67 c19 -198 108 -320 303 -416 138 -68
232 -87 435 -87 190 0 272 15 380 70 77 39 163 125 196 197 27 59 29 71 29
188 0 115 -2 130 -27 183 -40 84 -79 130 -162 185 -92 61 -203 108 -436 183
-477 154 -672 250 -836 414 -88 87 -139 175 -169 290 -24 90 -21 270 4 369 67
257 306 468 608 540 150 35 144 34 373 32 186 -3 214 -5 300 -29z m-286 -325
c4 -7 -31 -55 -174 -235 -25 -31 -51 -57 -59 -57 -8 0 -48 22 -90 48 -42 27
-95 59 -119 72 -23 13 -41 29 -39 34 7 21 103 86 150 102 103 34 317 58 331
36z m-1042 -617 c5 -17 -6 -19 -39 -7 -29 10 -32 14 -24 35 5 14 15 44 22 68
l12 44 13 -65 c7 -36 14 -69 16 -75z m831 -651 c397 -130 607 -212 607 -237 0
-5 -10 -7 -22 -3 -30 8 -165 35 -403 81 -228 44 -497 102 -531 115 -14 6 -22
15 -19 21 6 9 125 99 132 99 2 0 108 -34 236 -76z m776 -721 c-14 -37 -49 -93
-58 -93 -6 0 -125 50 -221 93 -22 10 -15 12 55 17 44 4 115 7 157 8 l78 2 -11
-27z"/>
<path d="M4431 9082 c-5 -9 -46 -110 -91 -222 -44 -113 -87 -218 -95 -235 -7
-16 -80 -194 -160 -395 -185 -458 -254 -628 -361 -894 -47 -116 -135 -332
-195 -481 -212 -522 -279 -693 -279 -713 0 -40 18 -43 251 -40 l224 3 27 60
c46 99 53 110 73 114 16 3 17 1 7 -11 -20 -25 61 -58 139 -58 128 0 223 91
279 265 7 22 15 47 18 55 3 8 6 17 7 20 28 81 79 174 115 210 64 64 98 70 464
76 316 5 318 4 337 -17 22 -26 49 -93 169 -434 51 -143 97 -266 102 -272 8
-10 67 -13 234 -13 189 0 225 2 238 16 20 20 20 19 -5 90 -14 39 -22 93 -25
161 -4 96 -9 114 -64 258 -32 86 -67 164 -77 173 -10 10 -18 32 -19 52 -1 42
-97 299 -131 353 -13 21 -28 62 -33 90 -9 57 -57 178 -95 243 -14 24 -25 49
-25 58 0 8 -7 30 -14 48 -14 30 -32 77 -86 223 -10 28 -33 88 -51 134 -18 47
-34 96 -35 110 -3 27 -71 217 -123 345 -16 39 -41 101 -56 138 -29 74 -230
463 -253 491 -13 15 -37 17 -208 17 -175 0 -194 -2 -203 -18z m384 -59 c9 -21
111 -290 227 -598 225 -598 283 -752 627 -1667 122 -323 221 -595 221 -603 0
-13 -27 -15 -187 -15 -104 0 -193 4 -199 8 -6 4 -55 131 -108 282 -54 151
-115 322 -136 380 l-38 105 -617 0 -617 0 -33 -85 c-19 -47 -70 -182 -115
-300 -114 -302 -144 -374 -155 -382 -5 -4 -94 -8 -197 -8 -148 0 -188 3 -188
13 0 7 36 103 81 213 44 109 142 350 216 534 75 184 164 405 198 490 296 733
651 1610 666 1648 8 22 12 22 174 22 l165 0 15 -37z m-760 -2448 c55 -14 107
-29 115 -33 8 -3 -48 -11 -125 -17 -77 -6 -143 -9 -147 -8 -4 2 0 24 9 49 11
34 20 44 32 41 9 -3 61 -17 116 -32z"/>
<path d="M4623 8568 c-5 -7 -28 -62 -51 -123 -58 -156 -221 -583 -345 -906
-59 -152 -107 -282 -107 -288 0 -8 136 -11 490 -11 371 0 490 3 490 12 0 11
-77 233 -322 923 -155 440 -143 408 -155 393z m137 -500 c0 -8 -1 -8 -127 52
-52 24 -95 51 -98 60 -3 10 2 37 11 60 l17 43 98 -104 c54 -57 98 -107 99
-111z m107 -293 c16 -44 49 -132 71 -195 23 -63 54 -152 71 -198 24 -68 27
-84 15 -89 -8 -3 -103 -3 -212 -2 -167 3 -201 7 -215 20 -9 9 -14 19 -11 22 3
3 8 19 10 36 3 17 21 75 40 128 20 54 36 104 37 111 0 6 4 12 9 12 4 0 8 8 8
18 0 10 4 22 8 28 5 5 21 43 36 84 15 41 39 90 53 108 23 31 26 32 37 15 7
-10 26 -54 43 -98z m-349 -3 c50 -36 91 -67 89 -68 -3 -4 -218 84 -226 91 -2
3 1 16 7 31 14 29 5 33 130 -54z m-152 -127 c10 -8 14 -15 8 -15 -5 0 -19 7
-30 15 -10 8 -14 15 -8 15 5 0 19 -7 30 -15z"/>
<path d="M3868 5529 c-22 -8 -28 -17 -28 -40 0 -36 -18 -38 -68 -4 -50 34 -96
33 -133 -4 -33 -34 -37 -69 -12 -112 16 -26 16 -29 0 -47 -9 -10 -17 -26 -17
-35 0 -22 0 -23 -88 20 -93 44 -180 51 -249 19 -41 -18 -43 -18 -80 0 -41 19
-79 21 -278 12 l-120 -5 -205 70 c-113 38 -217 76 -232 83 -20 11 -79 14 -242
14 -202 0 -216 -1 -226 -19 -11 -22 -43 -652 -34 -676 7 -17 6 -17 164 -40 63
-9 237 -36 386 -61 149 -24 288 -44 308 -44 45 0 76 15 76 36 0 19 32 16 225
-21 108 -21 141 -23 325 -18 113 2 214 9 226 14 14 7 39 7 79 0 77 -14 148
-14 163 1 9 9 26 9 75 0 36 -7 76 -12 91 -12 18 0 26 -5 26 -17 0 -10 4 -24 9
-31 4 -7 88 -30 187 -51 98 -21 197 -44 219 -50 59 -16 103 -14 150 9 47 23
71 60 125 192 19 48 38 89 41 92 3 4 69 -21 145 -55 240 -106 221 -101 353
-93 95 5 120 10 137 26 21 19 23 19 55 2 58 -29 125 -43 166 -33 21 5 70 9
109 9 62 0 73 3 92 25 l21 24 58 -30 c63 -33 116 -36 179 -9 19 8 38 10 50 5
51 -22 108 -32 143 -24 20 5 70 9 111 9 65 0 77 3 88 21 11 16 16 18 28 9 10
-9 14 -9 14 -1 0 8 11 6 33 -5 58 -31 130 -43 184 -31 26 6 86 9 133 8 85 -2
86 -2 115 31 111 125 115 137 61 203 l-35 42 15 48 c18 60 18 149 1 163 -16
14 -106 19 -131 7 -17 -7 -18 -6 -12 11 56 136 59 207 11 269 -92 122 -309 87
-353 -57 -6 -18 -13 -82 -17 -142 -5 -85 -11 -115 -25 -133 -17 -22 -19 -23
-28 -6 -4 10 -9 36 -11 58 -4 59 -38 123 -80 151 -91 60 -220 54 -308 -13 -26
-20 -48 -32 -50 -27 -12 34 -32 60 -52 69 -14 6 -32 31 -47 63 l-23 52 -63 0
c-35 0 -68 -4 -75 -8 -6 -4 -13 -27 -15 -52 -3 -41 -5 -45 -37 -51 -37 -8 -51
-23 -51 -56 0 -30 -4 -29 -41 6 -54 52 -157 74 -238 51 -38 -11 -38 -10 -157
124 -58 64 -64 68 -109 71 -27 2 -59 -2 -71 -7 -21 -10 -24 -18 -26 -97 l-3
-86 -53 3 c-96 6 -188 -42 -227 -119 -9 -16 -19 -29 -24 -29 -10 0 -180 109
-200 128 -9 9 -38 12 -90 11 l-76 -1 -149 86 c-83 48 -155 91 -161 96 -18 15
-97 21 -127 9z m87 -361 c-4 -178 -10 -327 -15 -331 -4 -5 -23 -7 -41 -5 l-34
3 3 135 c1 74 5 222 8 328 l7 192 40 0 39 0 -7 -322z m1143 -5 l-3 -328 -37
-3 c-29 -2 -38 0 -38 12 0 20 -2 20 -47 -4 -46 -24 -122 -26 -169 -4 -43 21
-91 93 -100 151 -9 62 2 166 22 205 39 78 87 108 172 108 44 0 66 -5 84 -20
14 -10 28 -16 32 -13 3 3 6 55 6 115 l0 108 40 0 40 0 -2 -327z m-1356 283
c25 -36 -22 -87 -61 -66 -30 16 -29 68 2 79 24 9 48 4 59 -13z m3039 -1 c56
-30 79 -96 55 -160 -15 -39 -37 -62 -100 -108 -19 -14 -17 -18 43 -90 34 -42
66 -79 71 -82 13 -8 21 10 28 63 7 44 9 47 40 50 22 2 34 -2 38 -13 7 -18 -11
-96 -33 -142 -15 -32 -15 -33 26 -80 l41 -48 -46 -3 c-36 -3 -51 1 -65 17
l-19 20 -52 -24 c-88 -41 -205 -32 -264 21 -49 44 -68 127 -43 191 6 15 31 47
57 71 39 37 44 46 34 60 -52 74 -64 134 -38 197 31 74 142 103 227 60z m-4463
-22 l3 -33 -154 0 -154 0 -7 -52 c-10 -88 -7 -158 7 -159 6 -1 64 -2 127 -3
l115 -1 0 -35 0 -35 -125 -3 c-84 -2 -127 -7 -132 -15 -4 -7 -8 -57 -8 -112 0
-137 -3 -145 -51 -145 -34 0 -39 3 -39 23 0 66 22 547 26 575 l5 32 192 -2
192 -3 3 -32z m3514 -65 l3 -53 40 -5 c37 -5 40 -7 40 -35 0 -28 -3 -30 -42
-33 l-43 -3 0 -158 c0 -173 3 -181 58 -181 32 0 33 -2 30 -32 -3 -32 -4 -33
-56 -36 -47 -3 -56 0 -82 27 l-30 29 0 176 0 176 -34 0 c-39 0 -57 17 -49 48
4 18 13 22 44 22 l39 0 0 48 c0 58 3 62 46 62 33 0 33 0 36 -52z m-3158 -81
c51 -34 57 -60 54 -262 l-2 -185 -34 0 c-20 0 -40 7 -46 15 -10 14 -16 13 -51
-5 -119 -61 -254 11 -242 129 10 89 73 132 205 139 92 5 98 10 88 67 -15 80
-140 87 -190 10 -13 -20 -23 -25 -44 -21 -15 2 -30 5 -34 5 -35 4 45 104 95
120 70 22 157 16 201 -12z m273 3 c7 -24 5 -24 45 0 23 14 51 20 91 20 63 0
93 -11 118 -43 16 -20 16 -20 54 8 58 45 175 49 222 8 44 -39 46 -47 44 -286
l-1 -158 -42 3 -42 3 2 175 c3 193 0 203 -58 220 -42 12 -97 -7 -124 -43 -19
-25 -22 -46 -26 -190 l-5 -162 -41 -3 -41 -3 5 176 c5 171 4 176 -17 199 -28
29 -68 38 -115 25 -62 -17 -69 -40 -76 -228 l-5 -166 -43 -3 -43 -3 6 123 c11
254 16 332 21 340 3 4 19 8 36 8 21 0 31 -6 35 -20z m788 11 c3 -5 2 -111 -1
-236 l-7 -226 -40 3 -40 3 2 170 c2 158 7 252 16 283 5 14 61 17 70 3z m441
-159 c29 -90 55 -166 57 -168 3 -3 28 66 57 153 29 87 57 164 63 171 11 14 63
16 72 2 6 -10 -146 -437 -195 -546 -36 -81 -97 -123 -157 -108 -18 5 -23 13
-23 40 0 32 2 34 35 34 44 0 75 29 93 85 12 39 10 48 -62 264 -42 123 -73 227
-70 233 3 5 22 8 42 6 l36 -3 52 -163z m1344 145 c63 -42 68 -62 72 -265 l3
-182 -42 0 c-32 0 -43 4 -43 15 0 19 -3 19 -52 -6 -62 -32 -156 -24 -198 16
-42 40 -56 91 -40 141 16 51 26 61 74 86 28 14 69 23 126 27 l85 6 -1 38 c-2
46 -26 75 -70 83 -33 6 -104 -11 -104 -25 0 -4 -9 -17 -20 -29 -22 -24 -73
-25 -78 -2 -4 26 35 78 75 99 56 30 168 29 213 -2z m780 0 c62 -41 68 -62 72
-264 l3 -183 -34 0 c-19 0 -39 7 -45 15 -10 14 -16 13 -51 -5 -48 -24 -124
-26 -171 -4 -74 35 -99 143 -48 204 40 47 80 63 174 69 l85 6 3 31 c2 22 -4
40 -22 60 -21 25 -31 29 -76 29 -46 0 -55 -4 -79 -31 -27 -32 -85 -52 -97 -33
-12 21 20 72 65 100 39 25 55 29 116 29 58 0 79 -4 105 -23z"/>
<path d="M4876 5229 c-44 -12 -65 -38 -82 -97 -27 -93 -1 -206 52 -231 43 -19
99 -14 132 14 29 24 30 30 37 131 5 69 4 114 -3 129 -20 44 -83 70 -136 54z"/>
<path d="M6645 5375 c-14 -13 -25 -36 -25 -50 0 -34 37 -105 55 -105 8 0 32
17 55 38 30 29 40 47 40 70 0 66 -78 95 -125 47z"/>
<path d="M6625 5092 c-55 -46 -68 -98 -40 -153 19 -35 54 -51 112 -50 45 0
103 24 103 43 0 12 -138 178 -148 178 -4 -1 -16 -8 -27 -18z"/>
<path d="M2483 5035 c-33 -14 -50 -49 -44 -90 6 -46 73 -69 133 -46 46 18 67
45 74 99 l7 52 -69 0 c-38 -1 -83 -7 -101 -15z"/>
<path d="M5345 5039 c-32 -18 -45 -40 -45 -75 0 -66 88 -96 157 -54 41 24 55
52 51 98 l-3 37 -70 2 c-38 2 -79 -2 -90 -8z"/>
<path d="M6134 5040 c-28 -11 -54 -48 -54 -78 0 -12 12 -34 26 -49 22 -22 32
-25 75 -21 72 7 109 44 109 110 l0 48 -67 -1 c-38 0 -78 -4 -89 -9z"/>
<path d="M8226 5528 c-11 -6 -31 -21 -45 -34 -14 -12 -59 -49 -100 -82 -73
-59 -73 -60 -150 -64 -87 -5 -117 -18 -187 -81 l-50 -45 -33 39 c-50 60 -102
83 -186 83 -94 0 -150 -26 -201 -93 -162 -212 -184 -255 -184 -361 1 -143 67
-224 194 -237 50 -5 73 1 173 42 l43 18 42 -29 41 -29 141 3 c195 5 258 4 356
-3 72 -5 97 -2 168 18 80 24 83 24 93 6 12 -23 80 -26 170 -7 49 10 65 9 109
-5 76 -25 112 -22 285 23 85 22 163 40 171 40 9 0 31 -13 50 -29 60 -54 125
-61 282 -35 89 15 99 15 142 0 25 -9 59 -16 75 -16 17 1 145 2 285 4 l255 3
337 56 c185 32 344 57 355 57 140 0 232 152 203 336 -27 168 -130 258 -275
239 l-55 -6 0 78 c0 48 -5 86 -12 96 -20 26 -96 32 -143 12 -42 -17 -343 -125
-501 -178 l-91 -31 -58 19 c-69 21 -153 17 -211 -13 -40 -20 -308 -122 -322
-122 -4 0 -20 20 -36 45 -42 68 -102 99 -190 99 -65 0 -79 -4 -163 -47 -51
-26 -97 -47 -102 -47 -5 0 -11 58 -13 130 -2 89 -7 133 -16 142 -8 8 -36 13
-70 12 -54 0 -64 -5 -173 -76 -146 -96 -166 -106 -215 -110 l-39 -3 -5 85 c-5
78 -7 86 -30 97 -31 16 -88 16 -114 1z m102 -155 c1 -62 5 -113 7 -113 3 0 19
9 37 20 49 30 142 28 190 -5 53 -36 82 -103 86 -198 5 -99 -11 -156 -58 -209
-36 -41 -39 -42 -106 -46 -61 -3 -73 -1 -107 22 -46 31 -47 31 -47 6 0 -16 -7
-20 -34 -20 -31 0 -34 3 -39 38 -5 28 -11 574 -8 615 1 5 18 7 39 5 l37 -3 3
-112z m516 -45 c3 -90 8 -238 10 -330 l5 -168 -39 0 c-21 0 -41 3 -43 8 -3 4
-8 152 -12 330 l-7 322 40 0 40 0 6 -162z m1840 146 c3 -9 6 -60 6 -115 0 -54
2 -99 5 -99 2 0 19 9 37 20 39 24 122 27 172 6 67 -28 116 -129 116 -239 0
-107 -35 -183 -99 -213 -46 -22 -141 -15 -180 13 l-28 22 -11 -23 c-8 -18 -16
-22 -40 -18 l-30 4 -6 86 c-3 48 -8 152 -11 232 -3 80 -8 189 -11 243 l-6 97
40 0 c27 0 42 -5 46 -16z m-3114 -194 c44 -22 77 -65 86 -109 5 -23 2 -32 -10
-37 -26 -10 -66 4 -66 24 0 27 -50 70 -90 77 -46 8 -98 -19 -122 -65 -25 -48
-24 -160 1 -217 23 -50 48 -65 107 -65 52 0 87 20 106 59 16 33 64 45 74 18
10 -25 -20 -80 -61 -111 -63 -48 -187 -46 -245 3 -50 42 -73 97 -78 191 -6
118 22 184 99 231 46 28 144 29 199 1z m469 2 c68 -34 75 -53 83 -245 3 -95 8
-179 10 -187 4 -18 -1 -20 -47 -20 -24 0 -35 5 -35 15 0 20 -12 19 -55 -6 -46
-27 -146 -24 -187 6 -54 40 -73 116 -43 179 24 51 83 78 184 85 l86 6 -2 40
c-4 51 -29 76 -84 82 -49 5 -109 -24 -109 -52 0 -8 -3 -15 -7 -16 -5 0 -16 -2
-27 -4 -27 -5 -46 5 -46 25 0 26 48 80 85 96 44 20 153 18 194 -4z m1217 -1
c63 -39 104 -118 104 -203 l0 -38 -155 0 -155 0 0 -27 c0 -35 31 -87 66 -109
41 -27 116 -22 159 10 28 22 38 25 55 16 27 -15 25 -31 -7 -62 -37 -36 -73
-48 -139 -48 -92 0 -155 37 -197 115 -18 32 -22 57 -22 131 0 84 2 94 30 140
41 66 92 94 170 94 42 0 70 -6 91 -19z m692 -7 c28 -16 49 -38 58 -60 20 -49
18 -54 -24 -54 -32 0 -41 5 -50 26 -33 72 -182 63 -182 -12 0 -35 22 -49 119
-79 119 -37 152 -64 158 -130 5 -65 -11 -94 -67 -122 -102 -52 -240 -19 -286
69 -24 46 -19 58 25 58 33 0 40 -4 45 -25 8 -33 54 -55 116 -55 77 0 111 45
75 97 -15 21 -29 27 -137 58 -33 9 -73 29 -93 46 -31 27 -35 37 -35 78 0 87
55 131 164 131 57 0 77 -5 114 -26z m253 -54 c4 -41 7 -118 8 -170 1 -72 5
-102 18 -120 24 -36 33 -40 85 -40 36 0 54 6 78 26 l31 26 -3 177 -3 176 35 0
35 0 6 -80 c4 -44 9 -149 12 -232 l6 -153 -38 0 c-25 0 -41 5 -45 15 -5 13
-10 12 -38 -5 -48 -29 -144 -28 -189 3 -19 12 -40 36 -48 52 -23 47 -43 380
-24 399 4 4 21 6 38 4 30 -3 30 -3 36 -78z"/>
<path d="M8410 5229 c-56 -12 -80 -51 -79 -129 1 -111 13 -161 45 -187 32 -25
100 -31 136 -12 32 17 58 86 57 154 -1 132 -59 195 -159 174z"/>
<path d="M10776 5229 c-16 -4 -39 -18 -52 -30 -22 -20 -24 -31 -24 -108 0
-114 13 -157 53 -181 46 -28 111 -26 142 4 48 48 57 172 18 253 -25 54 -80 79
-137 62z"/>
<path d="M7884 5040 c-28 -11 -54 -48 -54 -78 0 -12 12 -34 26 -49 22 -22 32
-25 75 -21 72 7 109 44 109 110 l0 48 -67 -1 c-38 0 -78 -4 -89 -9z"/>
<path d="M9133 5232 c-36 -11 -71 -48 -78 -82 -4 -17 -5 -34 -2 -37 7 -6 217
-6 224 0 7 8 -31 90 -49 104 -19 16 -67 24 -95 15z"/>
<path d="M1750 3853 c-30 -11 -40 -37 -40 -103 0 -43 5 -73 15 -86 16 -21 20
-74 6 -74 -4 0 -19 8 -32 19 -57 45 -168 62 -254 40 -94 -25 -85 -30 -85 47 0
52 -5 75 -20 94 l-19 25 -289 0 c-361 0 -332 14 -332 -166 0 -121 1 -129 22
-143 13 -9 43 -16 70 -16 25 0 58 -4 72 -10 23 -8 26 -15 26 -60 0 -46 -4 -55
-36 -85 -36 -34 -36 -35 -32 -106 6 -107 8 -108 214 -106 161 2 166 3 185 26
14 17 19 39 19 86 0 78 -5 92 -41 111 -27 13 -29 19 -29 74 0 32 4 61 8 64 5
3 26 2 47 -1 l37 -6 -6 -49 c-16 -119 36 -229 135 -283 49 -27 62 -30 144 -29
59 1 103 7 129 18 34 14 42 14 67 2 42 -21 275 -15 311 8 25 17 28 16 70 -6
36 -19 58 -22 134 -22 82 0 97 3 156 32 70 34 93 39 104 21 17 -28 98 -51 176
-51 66 0 88 5 144 31 37 17 72 31 78 31 7 0 38 -14 70 -31 51 -28 67 -31 134
-31 55 0 91 6 125 21 58 25 52 25 72 5 14 -14 91 -16 756 -16 676 0 742 2 759
17 17 15 21 15 59 -5 47 -23 155 -30 190 -10 12 6 27 8 33 4 6 -4 226 -7 489
-7 460 1 479 2 503 21 l25 19 53 -25 c74 -35 190 -34 267 3 l54 26 63 -32 c56
-28 69 -31 116 -25 29 4 123 11 208 15 124 6 163 5 195 -7 29 -10 61 -12 115
-7 41 4 133 9 205 11 115 5 133 8 152 26 l23 21 47 -22 c93 -43 196 -43 299 0
28 11 34 10 49 -4 17 -17 22 -18 195 -18 380 0 368 -1 381 22 16 31 14 157 -4
177 -9 11 -17 50 -21 103 -7 103 -23 146 -70 187 -42 36 -87 47 -159 38 -29
-4 -106 -6 -172 -6 -110 1 -123 -1 -148 -20 l-28 -22 -47 20 c-105 45 -213 37
-304 -24 -30 -21 -55 -31 -60 -26 -11 11 -12 60 -3 118 24 153 -198 217 -266
77 l-21 -44 -20 20 c-25 27 -50 26 -148 -5 -72 -22 -83 -29 -95 -57 -7 -18
-24 -42 -38 -55 l-24 -23 -53 28 c-66 34 -170 43 -251 20 -49 -13 -121 -14
-324 -4 -61 3 -96 -6 -147 -37 l-57 -34 -11 28 c-9 21 -9 34 -1 48 28 53 -6
149 -63 176 -115 53 -229 -29 -209 -151 8 -51 -16 -69 -61 -46 -42 22 -113 32
-151 21 -16 -5 -202 -8 -413 -8 -340 1 -386 -1 -411 -16 -26 -16 -31 -16 -61
0 -54 27 -133 31 -185 11 -39 -14 -51 -15 -77 -4 -32 13 -57 14 -259 8 -106
-3 -130 -7 -147 -23 -21 -18 -22 -18 -57 4 -48 30 -138 38 -189 16 -35 -15
-43 -15 -94 0 -36 11 -69 14 -96 9 -23 -3 -91 -6 -151 -5 -96 1 -114 -1 -135
-18 -23 -19 -24 -19 -94 6 -59 20 -81 23 -134 18 -35 -4 -84 -16 -108 -27
l-43 -21 -27 21 c-28 22 -33 23 -266 24 -53 0 -98 -16 -146 -52 -30 -22 -36
-23 -55 -11 -74 47 -113 62 -166 67 -75 7 -150 -11 -201 -48 -22 -16 -44 -29
-50 -29 -6 0 -10 45 -10 118 0 64 -4 122 -8 128 -16 25 -55 34 -144 33 -51 0
-100 -3 -108 -6z m5705 -80 c55 -56 13 -143 -69 -143 -39 0 -89 50 -89 89 0
54 57 95 115 83 10 -2 29 -16 43 -29z m-5497 -205 c-2 -128 -3 -240 -1 -249 2
-10 13 -19 24 -22 16 -4 19 -14 19 -56 l0 -51 -115 0 -115 0 0 50 c0 36 4 52
15 56 21 9 23 389 1 401 -7 4 -16 29 -18 56 l-6 47 101 0 100 0 -5 -232z
m4047 207 c63 -62 -1 -163 -90 -141 -64 16 -88 96 -43 143 31 33 100 32 133
-2z m-4703 -27 c2 -7 2 -55 0 -105 l-4 -93 -60 0 -60 0 3 48 c3 46 3 47 -29
50 l-32 3 0 -175 0 -176 30 0 c30 0 30 -1 30 -55 l0 -55 -150 0 -150 0 0 55 0
55 35 0 35 0 0 175 0 175 -35 0 -35 0 0 -50 0 -50 -60 0 -60 0 0 105 0 105
269 0 c206 0 270 -3 273 -12z m5878 -88 l0 -60 35 0 35 0 0 -55 0 -55 -35 0
-36 0 3 -102 3 -103 33 3 32 3 0 -51 c0 -61 -7 -65 -102 -65 -107 0 -127 35
-128 218 0 88 -2 97 -19 97 -14 0 -20 10 -25 46 -7 53 -3 64 25 64 15 0 19 7
19 38 0 38 0 38 63 59 97 33 97 33 97 -37z m-377 -51 c91 -25 127 -92 127
-238 0 -60 2 -69 20 -74 16 -4 20 -14 22 -46 0 -23 1 -46 2 -52 1 -15 -173
-12 -191 3 -10 8 -19 6 -33 -7 -28 -26 -102 -31 -155 -11 -112 43 -115 204 -5
258 39 19 112 23 140 8 25 -13 33 2 14 28 -18 27 -86 30 -140 7 -20 -8 -40
-13 -44 -10 -13 8 -32 74 -27 89 16 41 184 69 270 45z m-5196 -14 c88 -26 129
-91 121 -195 l-3 -45 -127 -3 c-71 -1 -128 -6 -128 -10 0 -4 7 -18 16 -30 12
-18 25 -22 67 -22 29 1 63 7 77 15 36 21 42 19 73 -24 l27 -39 -27 -20 c-71
-50 -207 -61 -280 -24 -159 83 -145 337 23 397 51 18 99 18 161 0z m714 0 c84
-30 123 -94 117 -190 l-3 -50 -127 -3 c-74 -1 -128 -7 -128 -12 0 -6 9 -19 20
-30 25 -25 97 -27 147 -4 l34 17 29 -34 c16 -19 29 -37 30 -41 1 -17 -79 -57
-134 -68 -139 -28 -249 34 -277 157 -42 182 114 320 292 258z m415 -16 c14
-11 18 -11 29 4 8 12 26 17 59 17 l47 0 -3 -92 -3 -93 -58 -3 c-57 -3 -57 -3
-63 28 -8 36 -37 60 -65 53 -52 -14 -65 -138 -19 -175 29 -24 75 -23 116 3
l33 20 30 -33 c37 -40 38 -49 9 -75 -62 -55 -199 -71 -278 -30 -67 33 -103 92
-108 173 -10 151 85 247 220 223 21 -3 45 -12 54 -20z m450 16 c27 -8 62 -27
78 -41 88 -83 87 -251 -2 -326 -45 -38 -94 -51 -172 -46 -57 3 -77 9 -112 33
-64 45 -90 102 -86 192 3 62 7 76 34 109 70 88 151 112 260 79z m529 -15 l22
-21 44 26 c94 53 199 18 222 -75 3 -14 7 -67 8 -117 1 -84 3 -93 20 -93 16 0
19 -8 19 -55 l0 -55 -105 0 -105 0 0 144 c0 119 -3 145 -15 150 -41 15 -59
-25 -53 -124 3 -48 6 -55 25 -58 18 -3 22 -11 25 -58 l3 -54 -110 0 -110 0 3
132 c2 106 0 137 -12 153 -12 16 -18 18 -33 9 -15 -9 -19 -27 -21 -98 l-3 -86
26 0 c24 0 25 -3 25 -55 l0 -55 -122 0 -123 0 3 55 c3 39 8 55 18 55 11 0 14
20 14 95 0 75 -3 95 -14 95 -13 0 -26 74 -18 100 2 6 37 10 79 10 66 0 75 -2
80 -20 7 -25 5 -25 50 6 32 22 45 25 87 20 30 -3 58 -14 71 -26z m729 1 l24
-23 30 22 c40 30 116 37 165 16 54 -22 78 -80 83 -200 3 -71 7 -92 19 -94 8
-2 15 -14 15 -26 0 -13 3 -38 6 -55 l7 -31 -112 0 -111 0 0 139 c0 144 -7 169
-44 155 -12 -5 -16 -21 -17 -65 -1 -32 -2 -71 -3 -86 -1 -22 4 -29 21 -31 18
-3 22 -11 25 -58 l3 -54 -110 0 -110 0 3 102 c4 125 -4 187 -25 195 -29 11
-43 -26 -43 -109 0 -77 0 -78 25 -78 24 0 25 -3 25 -55 l0 -55 -120 0 -120 0
0 55 c0 42 3 55 15 55 12 0 15 16 15 88 0 75 -3 90 -20 105 -15 12 -20 29 -20
62 l0 45 81 0 c72 0 81 -2 86 -20 7 -25 5 -25 50 6 31 21 46 25 86 21 31 -3
56 -12 71 -26z m1281 -1 c36 -33 49 -83 49 -187 1 -79 3 -93 19 -97 13 -3 17
-15 17 -55 l0 -51 -105 0 -104 0 -3 143 -3 142 -25 3 c-45 4 -55 -11 -58 -98
l-4 -80 31 0 c31 0 31 0 31 -55 l0 -55 -125 0 -125 0 0 55 c0 42 3 55 15 55
12 0 15 17 15 94 0 83 -2 94 -19 99 -15 4 -18 13 -16 48 1 24 3 47 4 52 0 4
39 7 86 7 75 0 85 -2 96 -21 11 -21 11 -21 32 -1 32 28 45 32 109 29 44 -3 64
-9 83 -27z m631 5 c19 -12 23 -12 28 0 4 11 21 15 56 15 l50 0 0 -95 0 -95
-65 0 c-59 0 -65 2 -65 21 0 60 -53 79 -89 33 -32 -41 -28 -98 9 -136 27 -26
36 -30 67 -25 19 4 43 13 53 22 25 22 33 19 69 -26 l33 -40 -24 -21 c-43 -38
-109 -60 -178 -60 -141 1 -220 81 -220 224 0 71 28 139 66 165 67 43 159 52
210 18z m1532 -12 c111 -69 127 -250 30 -340 -76 -71 -240 -71 -316 0 -55 51
-79 135 -62 211 25 111 112 170 240 161 47 -3 76 -12 108 -32z m516 16 c44
-35 58 -74 64 -185 4 -82 8 -104 20 -106 12 -2 16 -17 16 -55 l1 -53 -107 0
-108 0 0 129 c0 71 -3 136 -6 145 -4 10 -18 16 -35 16 -37 0 -49 -28 -49 -116
l0 -64 30 0 c30 0 30 -1 30 -55 l0 -55 -127 0 -128 0 3 55 c3 39 8 55 18 55
11 0 14 20 14 95 0 74 -3 95 -14 95 -14 0 -31 84 -20 101 3 5 44 9 91 9 76 0
85 -2 90 -20 6 -24 16 -25 34 -4 33 40 140 47 183 13z m-3404 -122 c0 -141 9
-173 45 -165 31 5 45 47 43 118 -3 59 -5 65 -28 70 -22 5 -25 12 -28 58 l-3
52 110 0 111 0 0 -149 c0 -140 1 -149 20 -154 17 -4 20 -14 20 -56 l0 -51 -84
0 c-78 0 -85 2 -88 21 -3 19 -4 19 -37 -5 -28 -21 -46 -26 -94 -26 -118 0
-157 56 -157 230 0 49 -4 71 -14 77 -8 5 -15 29 -17 61 l-4 52 103 0 102 0 0
-133z m1020 -16 c0 -140 1 -149 20 -154 17 -4 20 -14 20 -56 l0 -51 -117 0
-118 0 3 55 c3 39 8 55 18 55 11 0 14 19 14 90 0 75 -3 91 -17 99 -13 6 -19
24 -21 60 l-3 51 100 0 101 0 0 -149z m1450 0 c0 -142 1 -149 21 -154 17 -5
20 -12 17 -56 -2 -28 -6 -51 -10 -51 -5 0 -55 0 -113 0 l-105 0 0 55 c0 42 3
55 15 55 12 0 15 17 15 95 0 78 -3 95 -15 95 -12 0 -15 13 -15 55 l0 55 95 0
95 0 0 -149z m-220 -66 c0 -14 -4 -25 -9 -25 -4 0 -6 11 -3 25 2 14 6 25 8 25
2 0 4 -11 4 -25z"/>
<path d="M6682 3348 c-17 -17 -15 -43 3 -58 23 -19 65 3 65 34 0 33 -43 49
-68 24z"/>
<path d="M1494 3475 c-14 -14 -22 -30 -19 -35 3 -6 31 -10 61 -10 47 0 54 3
54 19 0 25 -26 51 -51 51 -11 0 -31 -11 -45 -25z"/>
<path d="M2204 3475 c-14 -14 -22 -30 -19 -35 3 -6 31 -10 61 -10 47 0 54 3
54 19 0 21 -35 51 -58 51 -7 0 -24 -11 -38 -25z"/>
<path d="M3074 3475 c-22 -33 -21 -127 0 -161 22 -32 59 -32 74 1 7 14 12 52
12 84 0 44 -5 62 -20 79 -27 29 -46 28 -66 -3z"/>
<path d="M7744 3492 c-36 -23 -47 -139 -18 -180 36 -52 84 -10 84 76 0 63 -22
112 -50 112 -3 0 -10 -4 -16 -8z"/>
<path d="M10943 3665 c-29 -8 -76 -26 -103 -40 l-51 -26 -56 28 c-56 27 -58
27 -242 25 -164 -2 -189 -5 -212 -22 -26 -18 -27 -18 -74 6 -55 28 -119 31
-180 9 -35 -12 -46 -12 -94 2 -35 10 -68 13 -95 8 -23 -3 -91 -6 -153 -5 -101
1 -113 0 -132 -19 -26 -26 -27 -26 -79 -1 -68 33 -146 36 -236 10 -72 -22 -77
-22 -95 -6 -18 17 -73 20 -269 17 -98 -1 -179 -64 -214 -165 -31 -92 -14 -203
42 -273 84 -103 246 -127 384 -57 l53 27 66 -33 c85 -42 162 -45 259 -10 62
21 67 22 83 7 9 -10 22 -17 28 -18 7 0 140 -1 297 -3 157 -2 309 -4 338 -5
l52 -1 0 -59 c0 -43 5 -65 19 -82 18 -22 28 -24 131 -27 181 -6 200 4 210 107
5 51 7 55 34 58 33 5 78 23 116 48 25 16 27 16 79 -17 47 -29 62 -33 115 -32
213 4 799 4 803 0 3 -3 -2 -24 -11 -47 -15 -37 -15 -44 -2 -64 45 -69 193 -86
275 -32 58 38 86 87 166 287 52 131 74 174 91 180 28 11 34 29 34 109 0 107
15 103 -327 101 -158 -1 -315 3 -348 8 -38 6 -69 6 -85 0 -14 -6 -81 -9 -151
-9 -115 2 -127 0 -153 -20 l-27 -21 -47 25 c-45 23 -133 48 -170 46 -9 0 -41
-7 -69 -14z m192 -69 c77 -35 89 -60 95 -191 5 -105 6 -110 28 -113 19 -3 22
-9 22 -53 l0 -49 -89 0 c-71 0 -90 3 -95 15 -5 14 -10 12 -37 -7 -23 -17 -44
-23 -82 -23 -64 0 -104 20 -135 65 -20 29 -23 43 -18 84 12 104 124 163 229
121 22 -9 23 17 1 39 -20 20 -102 21 -149 1 l-35 -14 -15 36 c-20 48 -19 60 8
74 81 44 196 51 272 15z m-2162 -15 c17 -14 20 -14 32 2 8 12 26 17 59 17 l46
0 1 -92 c1 -79 2 -88 11 -58 14 50 58 106 103 132 58 34 162 32 230 -2 118
-61 143 -256 44 -347 -75 -69 -231 -72 -310 -6 -39 33 -68 95 -69 145 l0 37
-67 3 c-65 3 -67 4 -75 33 -23 87 -108 46 -108 -52 0 -31 25 -79 45 -87 31
-11 68 -6 102 15 19 12 38 18 41 13 4 -5 21 -24 37 -42 l30 -33 -25 -20 c-50
-40 -109 -59 -181 -59 -140 0 -219 78 -219 216 0 84 26 144 77 175 65 40 157
45 196 10z m982 -1 c22 -21 45 -26 45 -12 0 5 18 17 40 27 41 18 110 16 149
-5 38 -20 60 -88 62 -189 1 -82 4 -94 20 -98 16 -4 19 -15 19 -59 l0 -54 -105
0 -105 0 0 144 c0 119 -3 145 -15 150 -40 15 -60 -36 -53 -134 2 -38 7 -46 26
-48 20 -3 22 -9 22 -58 l0 -54 -105 0 -105 0 0 131 c0 130 -8 169 -35 169 -24
0 -35 -35 -35 -114 0 -74 1 -76 25 -76 24 0 25 -3 25 -55 l0 -55 -126 0 c-111
0 -125 2 -120 16 3 9 6 33 6 55 0 28 4 39 15 39 21 0 22 175 1 187 -8 5 -15
29 -17 58 l-4 50 77 3 c70 3 78 1 88 -18 11 -20 13 -20 29 -6 44 40 136 43
176 6z m1751 -3 c32 -37 44 -88 44 -184 0 -72 2 -84 20 -93 16 -8 20 -21 20
-60 l0 -50 -102 0 -103 0 0 136 c0 123 -2 137 -19 150 -26 18 -32 18 -56 -6
-16 -16 -20 -33 -20 -94 0 -74 0 -75 28 -78 25 -3 27 -7 30 -55 l3 -53 -125 0
-126 0 0 50 c0 36 4 52 15 56 12 5 15 26 15 100 0 77 -3 94 -15 94 -12 0 -15
13 -15 55 l0 55 86 0 c77 0 86 -2 91 -20 7 -25 5 -25 50 6 33 22 46 25 97 22
49 -4 63 -9 82 -31z m-1194 11 c4 -10 10 -10 32 0 41 18 121 15 165 -8 132
-67 126 -312 -10 -383 -35 -18 -124 -20 -161 -3 -15 7 -18 3 -18 -28 0 -29 4
-36 20 -36 18 0 20 -7 20 -55 l0 -55 -118 0 c-65 0 -121 2 -123 4 -2 2 -2 27
-1 55 3 35 8 51 18 51 22 0 21 348 -1 356 -11 5 -15 21 -15 60 l0 54 94 0 c66
0 96 -4 98 -12z m1518 -38 c0 -35 -5 -52 -16 -58 -15 -9 -15 -13 1 -56 10 -26
22 -49 26 -52 7 -4 49 78 49 97 0 5 -7 9 -15 9 -12 0 -15 13 -15 55 l0 55 106
0 105 0 -3 -52 c-3 -45 -6 -53 -24 -56 -17 -2 -29 -23 -57 -95 -81 -209 -131
-317 -159 -346 -52 -53 -157 -64 -208 -23 -21 17 -21 17 10 65 29 47 30 47 54
32 31 -21 58 -8 75 34 12 27 8 39 -66 179 -46 90 -85 153 -95 155 -14 4 -18
16 -18 56 l0 51 125 0 125 0 0 -50z m-148 -325 c13 -28 6 -40 -17 -32 -17 7
-21 57 -5 57 6 0 16 -11 22 -25z"/>
<path d="M10993 3353 c-22 -8 -16 -61 7 -68 28 -9 58 3 65 26 12 36 -29 60
-72 42z"/>
<path d="M9309 3484 c-21 -26 -25 -139 -5 -169 23 -35 63 -34 77 3 17 43 7
152 -16 168 -25 18 -40 18 -56 -2z"/>
<path d="M10554 3482 c-56 -37 -39 -192 21 -192 65 0 74 169 10 194 -20 7 -18
7 -31 -2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
